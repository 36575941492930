body{color: #585858;}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mt-2{margin-top:2em;}
/*.cont-tabs{padding: 0 0 25px 0;}*/
.MuiTabs-indicator{top: 0;height: 4px !important;background-color: #93289E !important;}
.tabs-content .Mui-selected {background: #F3CCF7 !important;}
.tab-button{font-size: 16px !important;}
.container-modal{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}
.btn-hotel{background: #93289E !important;color: #fff !important;width: 100%;padding: 15px !important;text-transform: capitalize !important;}
.btn-modal{margin-top:15px !important;text-align:center;justify-self:center;background: #93289E !important;color: #fff !important;width: 50%;padding: 15px !important;text-transform: capitalize !important;}
.tab-button{text-transform: capitalize !important;width: auto !important;margin: auto !important;}
.contLogo img{height: 55px;}
.contLogo{width: 34px}
.bg-blanco{background: #fff !important;padding: 6px 0;color: #ab44b6 !important;}

.no-border{
  border:0 !important;
}
.contenedor-menu{
  display: inline-grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  gap: 0 15px;
  align-items: center;
}
/*.contenedor-menu .active,.contenedor-menu div:hover{box-shadow: inset 0 0 0 2px #93289E;border-radius: 5px;}*/
.contenedor-menu .active{background: #93289e;color: #fff;}
/*.contenedor-menu .active p:after{*/
/*  margin-top: 10px;*/
/*  content: "";*/
/*  display: flex;*/
/*  height: 3px;*/
/*  align-items: center;*/
/*  background:  #e89a51;*/
/*  bottom: 0;*/
/*  transition: 0.4s;*/
/*  width: 100% !important;*/
/*  background:  #e89a51;*/
/*  transition: 0.4s linear;*/
/*}*/
.contenedor-menu div{height: 50px;align-items: center;display: flex;cursor: pointer;padding: 0 15px;transition: 0.4s;}
/*.contenedor-menu p:after,.contenedor-menu .active p:after{*/
/*  margin-top: 10px;*/
/*  content: "";*/
/*  display: flex;*/
/*  height: 3px;*/
/*  align-items: center;*/
/*  background:  #e89a51;*/
/*  bottom: 0;*/
/*  width: 0;*/
/*  transition: 0.4s;*/

/*}*/
.contenedor-menu div:hover p:after{
  width: 100%;
  transition: 0.4s linear;
  background:  #e89a51;

}
/* ====== ESTILOS LISTA DE INQUILINOS =======*/
.card-info{
  position: sticky;
  top: 0;
  height: 100vh;
  padding-left: 0!important;
  z-index: 1;
}
.content-info{
  box-shadow: 0px 3px 8px rgb(0 0 0 / 10%);
  border: 1px solid #e7e7e7;
  padding: 20px;
  background: #fff;
}
.list-info{margin-top: 20px !important;}
.list-info .MuiListItem-root{border-bottom: 1px solid #e7e7e7 !important;padding: 5px 0px !important;}
.list-info span,.list-info p{
  color: #333;
  font-size: 15px;
  margin-left: 10px;
}
.avatar-icons{
  color: #93289e !important;
  background-color: #f3ccf7 !important;
  width: 30px !important;
  height: 30px !important;
}
.avatar-icons svg{font-size: 20px}
.total{
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 1.5em 0;
}
.total p{
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #333;
}
.txt-right{text-align: right;}
.MuiGrid-root{margin-left: 0 !important;}
.resumen-item{
  display: inline-grid;
  grid-template-columns: auto 1fr auto;
  margin-top: 15px;
  align-items: center;
  /*border-right: 5px solid #93289E;*/
  /*padding: 10px;*/
  box-shadow: 0px 3px 7px rgb(0 0 0 / 10%);width: 100%;
}
.resumen-item .div-img{
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
}
.resumen-item .div-img img{width: 100%;}
.resumen-item p{font-size: 15px;color: #333}
/*.resumen-item div{width: 100%;}*/
.adultos{font-size: 45px !important;}
.ninos{font-size: 35px !important;}
.contNoches{padding: 25px 15px;
  width: 58px;
  font-size: 16px !important;
  color: #333;}
.border-right{
  border-right: 5px solid #AB44B6;
}
.cont-btn-contacto{
  width: 100%;
  display: grid;
  justify-content: end;
  margin-top: 25px;
}
.cont-btn-contacto .btn-hotel{width: 210px;}
/*========= ESTILOS CALENDARIO ============*/
.DateInput_input__focused{border-bottom: 0 !important;border-top: 4px solid #93289e !important}
.DateRangePickerInput, .DateInput{display: inline-flex !important;width: auto !important;align-items: center;}
.DateRangePickerInput  .DateInput_input{text-align: center;}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #DE96E6 !important;
  border: 1px double #AB44B6 !important;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before{border-right: 33px solid #AB44B6 !important;}
.CalendarDay__selected_span,.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #F3CCF7 !important;
  border: 1px double #dda9e3  !important;
}
.D-grid{
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  text-align: center;
}
.icon-calendar p{font-size: 14px;margin-top: 0;}
.icon-calendar img{
  width: 30px;
  height: auto;
}
/*.select-item .MuiSelect-select{padding: 10px 15px;}*/
  /*======== ESTILOS ITEM =========*/
.txt-item{font-size: 14px !important;padding-right: 15px !important;}
.pd-15{padding: 15px;}
.list-item span{font-size: 14px !important;}
.descripcion-item{
  display: inline-grid;
  grid-template-columns: 1fr auto;
}
.precios-item {
  padding: 20px;
  text-align: center;
  border-left: 1px solid #dbdbdb;
}
.font-15{font-size: 15px !important;font-weight: bold !important;margin-bottom: 15px !important;}
.tashed{
  text-decoration: line-through;
  font-size: 18px;
  color: #b1b1b1;
  margin-bottom: -5px !important;
}
.fontBold-20{
  font-size: 25px !important;
  font-weight: 600 !important;
  color: #93289e !important;
}
.contenido{border-top: 1px solid #dbdbdb;margin-top: 15px;}
.cont-item img{width: 100%;}
.cont-tabPanel{
  border: 1px solid #dbdbdb;
  margin-top: 15px;
}
/* ============= ESTILOS DATOS CONTACTO ============== */

.cont-formulario{display: inline-grid;grid-gap: 20px;width: 100%;margin-top: 15px;}
.grid-form, .grid-form-3{
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 20px;
}
@media (max-width: 992px) {
  .grid-form, .grid-form-3 {
    grid-template-columns: auto !important;
  }
}
.grid-form-3{grid-template-columns: repeat(3, auto);}
.cont-formulario .MuiTextField-root{width: 100% !important;}
.cont-formulario .Mui-focused{}

/* ================== ESTILOS METO DE PAGO =================== */
.cont-metodo-pago{
  display: grid;
  grid-template-columns: 270px 270px;
  grid-gap: 20px;
  margin-top: 15px;
}
.logoPayPal{width: 95px;}
.cont-txt-paypal{
  display: grid;
  grid-template-columns: auto 1fr;
  background: #F3CCF7;
  border: 1px solid #DE96E6;
  align-items: center;
  padding: 15px;
  grid-gap: 20px;
  margin-top: 20px;
}
.checkbox{margin-top: 20px !important;}
.checkbox .Mui-checked .MuiSvgIcon-root{color: #93289e !important;}
.checkbox .MuiFormControlLabel-label{font-size: 14px;}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.CalendarMonthGrid{z-index: 1 !important;}
.btnMovilMenu{display: none !important;}
.container-modal{text-align: center;display: block}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 420px){
  .cont-100{width: 100% !important;}
  .card-info{height: 100%;margin-bottom: 25px !important;}
  .MuiGrid-root{padding-left: 0 !important;}
  .cont-item .pd-15{padding: 0;}
  .descripcion-item{display: flex;}
  .cont-item .D-grid{display: inline-block;}
  .card-info{position: inherit;}
  .cont-tabPanel{padding: 15px !important;}
  .contenedorItemsMenu{display:none !important}
  .btnMovilMenu{display: inline-block !important; margin-right: 0 !important;}
  .contenedor-menu{gap: 0px !important;}
  .cont-info-modal{width: 300px !important;padding: 15px !important;}
}
